import React, { useMemo } from 'react';
import { Box, Container, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import { Icon, Typography } from 'components';
import Error from 'next/error';

import { MembershipFeaturesBanner, useMembershipContext, Types, getCountryCode } from 'common';
import Image from 'next/image';
import { ExclusiveForMembersLabel } from '../../components/ExclusiveForMembersLabel';
import {
	CoursesTable,
	CoursesTableProps
} from '../../containers/MediathekHome/CoursesSection/components/CoursesTable';
import {
	getCustomTopicSortOrder,
	prepareCoursesData
} from '../../containers/MediathekHome/CoursesSection/CoursesSection.utils';
import {
	OnDemandCoursesQueryVariables,
	useOnDemandCoursesQuery,
	useTopicSortOrderQuery
} from '../../graphql/catalog/queries';
import { useRouter } from 'next/router';
import { ACTIVE_TOPIC_CODE } from '../../utils/topics';

import OnDemandPromoImage from '../../images/ondemand-landing-promo.png';
import SemiCircleImage from '../../images/semi-circle-light-banner.svg';
import { useStyles } from './OnDemandLandingPage.styles';
import { useIsOnDemandAccreditationEnabled } from '../../hooks/useIsOnDemandAccreditationEnabled';

export const getFetchOnDemandCoursesVariables = (
	countryCode: CountryCode
): OnDemandCoursesQueryVariables => ({
	searchQuery: {
		filters: [
			{
				field: 'contentType',
				value: [Types.ContentType.OnDemandAccredited]
			},
			{
				field: 'publishedDomains',
				value: [countryCode],
				operation: Types.SearchFilterOperation.Contain
			}
		],
		aggregations: [
			{
				field: 'productCode',
				name: 'productEvents',
				type: 'TERMS' as const
			}
		]
	}
});

export default function OnDemandLandingPage() {
	const classes = useStyles();
	const intl = useIntl();
	const { query } = useRouter();
	const countryCode = getCountryCode(intl.locale);
	const onDemandAccreditationEnabled = useIsOnDemandAccreditationEnabled();

	const { isMember } = useMembershipContext();

	const selectedTopicCode = useMemo(() => {
		return (query.topic || ACTIVE_TOPIC_CODE) as string;
	}, []);

	const { data: topicsResponse } = useTopicSortOrderQuery({
		variables: getCustomTopicSortOrder(selectedTopicCode)
	});

	const { data: coursesData } = useOnDemandCoursesQuery({
		variables: getFetchOnDemandCoursesVariables(countryCode)
	});

	const courses: CoursesTableProps['courses'] = prepareCoursesData({
		topicData: topicsResponse?.topicDocuments.data,
		coursesData: coursesData?.courses,
		intl,
		isOnDemandTable: true,
		isMember
	});

	if (!onDemandAccreditationEnabled) {
		return <Error statusCode={404} />;
	}

	return (
		<Box className={classes.pageContainer}>
			<Container maxWidth="xl" disableGutters>
				<Box className={classes.titleContainer}>
					<Box>
						<Typography
							variant="h1"
							color="primary"
							className={classes.title}
							localeId="catalog.on-demand.landing.title"
						/>
						<Typography
							variant="subtitle1"
							className={classes.subtitle}
							localeId="catalog.on-demand.landing.sub-title"
						/>
					</Box>
					<Icon icon="progressPlay" className={classes.titleIcon} />
				</Box>
				<Typography
					variant="subtitle1"
					className={classes.coursesTitle}
					localeId="catalog.on-demand.landing.courses.title"
				/>
				<ExclusiveForMembersLabel className={classes.coursesSubTitleContainer} />

				<CoursesTable
					courses={courses}
					variant={Types.ProductBrand.Ondemand}
					hideMoreCoursesLink
				/>

				<Box className={classes.benefitsContainer}>
					<Typography
						variant="h4"
						localeId="catalog.on-demand.landing.benefits.item1"
						className={classes.benefitsItem}
					/>
					<Divider className={classes.benefitDivider} />
					<Typography
						variant="h4"
						localeId="catalog.on-demand.landing.benefits.item2"
						className={classes.benefitsItem}
					/>
					<Divider className={classes.benefitDivider} />
					<Typography
						variant="h4"
						className={classes.benefitsItem}
						localeId="catalog.on-demand.landing.benefits.item3"
					/>
				</Box>

				<Container maxWidth="md" className={classes.innerContainer}>
					<Box className={classes.benefitsSectionContainer}>
						<Box>
							<Box className={classes.benefitsSectionTitleContainer}>
								<Typography
									variant="h2"
									localeId="catalog.on-demand.landing.benefits.section.title"
									className={classes.benefitsSectionTitle}
									color="primary"
								/>
								<Box className={classes.promoImagePhone}>
									<Image
										alt="Promotional image"
										src={OnDemandPromoImage}
										width={118}
										height={112}
									/>
								</Box>
							</Box>
							<ul className={classes.benefitsSectionList}>
								<li className={classes.benefitsSectionListItem}>
									<Icon icon="checkmark" />
									<Typography localeId="catalog.on-demand.landing.benefits.section.item1" />
								</li>
								<li className={classes.benefitsSectionListItem}>
									<Icon icon="checkmark" />
									<Typography localeId="catalog.on-demand.landing.benefits.section.item2" />
								</li>
							</ul>

							<Typography
								localeId="catalog.on-demand.landing.benefits.section.text"
								className={classes.benefitsSectionText}
							/>
						</Box>

						<Box className={classes.promoImage}>
							<Image
								alt="Promotional image"
								src={OnDemandPromoImage}
								width={255}
								height={243}
							/>
						</Box>
					</Box>
					{!isMember && (
						<Box>
							<SemiCircleImage className={classes.semiCircleBanner} />
							<Typography
								variant="h2"
								localeId="catalog.on-demand.landing.membership.title"
								color="primary"
								className={classes.membershipSectionTitle}
							/>
							<MembershipFeaturesBanner isOnDemandCourseBanner />
						</Box>
					)}

					<Box className={classes.accreditationContainer}>
						<Typography
							localeId="catalog.product.accreditation"
							className={classes.membershipSectionTitle}
							variant="h2"
							color="primary"
						/>
						<Box className={classes.accreditationInnerContainer}>
							<Box className={classes.accreditationBadge}>
								<div />
								<Typography
									localeId="catalog.on-demand.landing.accreditation.badge"
									className={classes.accreditationBadgeText}
								/>
							</Box>
							<Typography
								localeId="catalog.on-demand.landing.accreditation.text"
								className={classes.accreditationText}
							/>
						</Box>
					</Box>
				</Container>
			</Container>
		</Box>
	);
}
